






















import UsersCondition from '@/includes/logic/Modules/models/conditions/UsersCondition/UsersCondition'
import { InputSetups } from '@/mixins/input-setups'
import GetUserInfoService from "@/includes/logic/Modules/GetUserInfoService";
import UsersInfoMixin from "@/components/Modules/conditions/UsersCondition/UsersInfoMixin";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FieldData } from 'piramis-base-components/src/components/Pi/types';
import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ValidationObserver
  }
})
export default class UsersConditionView extends Mixins(UseFields, InputSetups, UsersInfoMixin) {
  @VModel() model!: UsersCondition

  @Prop() usersInfoService!: GetUserInfoService

  modelSetter(args: FieldData) {
    args.setter = (value: BlackWhiteListItem<number>) => this.userInfoModelSetter(args, { model: value, path: 'items' }, this.model.values.items, this.usersInfoService)

    return args
  }

  format(tag: string | null) {
    return this.formatter(this.usersInfoService.usersInfos, tag)
  }
}
